<script setup>
    import axios from 'axios';
    import { computed, onMounted, ref } from 'vue';
    const teams = ref([]);
    const team1 = ref();
    const team2 = ref();
    // const team1 = ref({ "id": 5, "team": "Omonia", "city": "Leukosia", "img_url": "omonoia_fc.png" } );
    // const team2 = ref({ "id": 4, "team": "Nea Salamina", "city": "Amoxostos", "img_url": "nea_salamina.png" });
    const matches = ref([]);
    const searchTeamQuery = ref('');
    const seasonSelected = ref('');
    const phaseSelected = ref('');
    const homeTeamSelected = ref('');

    const getTeams = async () => {
        const res = await axios.get('https://cyprus-league-demo.onrender.com/api/teams');
        teams.value = res.data;
    };

    const getData = async() => {
        const res = await axios.get(`https://cyprus-league-demo.onrender.com/api/all-head-2-head`);
        matches.value = res.data;
    };

    const filteredTeams = computed(() => {
        if (searchTeamQuery.value.length < 1) return teams.value.slice().sort((a, b) => a.team.localeCompare(b.team));
        return teams.value
            .filter(team => team.team.toLowerCase().includes(searchTeamQuery.value.toLowerCase()))
            .sort((a, b) => a.team.localeCompare(b.team)); // Sort alphabetically
    });


    const resetFitlers = () => {
        seasonSelected.value = '';
        phaseSelected.value = '';
        homeTeamSelected.value = '';
    }

    const filteredMatches = computed(() => {
        let newMatches = [];
        if (team1.value && team2.value) {
            newMatches =  matches.value.filter(match => {
                const isTeam1VsTeam2 = (match.home_team_name === team1.value.team && match.away_team_name === team2.value.team);
                const isTeam2VsTeam1 = (match.home_team_name === team2.value.team && match.away_team_name === team1.value.team);
                return isTeam1VsTeam2 || isTeam2VsTeam1;
            });
            if (seasonSelected.value) {
                newMatches = newMatches.filter(match => match.season_name === seasonSelected.value);
            }

            if (phaseSelected.value) {
                newMatches = newMatches.filter(match => match.phase_name === phaseSelected.value);
            }

            if (homeTeamSelected.value) {
                newMatches = newMatches.filter(match => match.home_team_name === homeTeamSelected.value);
            }

            return newMatches;
        } else {
            return [];
        }
    });


    const uniqueSeasons = computed(() => {
        const seasons = matches.value.map(match => match.season_name);
        return [...new Set(seasons)].sort();
    })

    const uniquePhases = computed(() => {
        const phases = matches.value.map(match => match.phase_name);
        return [...new Set(phases)].sort();
    })


    const scores = computed(() => {
        let homeTeamWins = 0;
        let draws = 0;
        let awayTeamWins = 0;
        
        
        if(filteredMatches.value.length === 0) return {homeTeamWins, draws, awayTeamWins};

        
        filteredMatches.value.forEach(match => {
            if (match.home_team_name === team1.value.team) {
                homeTeamWins += parseInt(match.result_home) >  parseInt(match.result_away) ? 1 : 0;
                draws += parseInt(match.result_home) ===  parseInt(match.result_away) ? 1 : 0;
                awayTeamWins += parseInt(match.result_home) <  parseInt(match.result_away) ? 1 : 0;
            } else if (match.home_team_name === team2.value.team) {
                homeTeamWins += parseInt(match.result_home) <  parseInt(match.result_away) ? 1 : 0;
                draws += parseInt(match.result_home) ===  parseInt(match.result_away) ? 1 : 0;
                awayTeamWins += parseInt(match.result_home) >  parseInt(match.result_away) ? 1 : 0;
            }
        });

        return {homeTeamWins, draws, awayTeamWins};
    });

    const goals = computed(() => {
        let homeTeamGoals = 0;
        let awayTeamGoals = 0;
        
        
        if(filteredMatches.value.length === 0) return {homeTeamGoals, awayTeamGoals};

        
        filteredMatches.value.forEach(match => {
            if (match.home_team_name === team1.value.team) {
                homeTeamGoals += parseInt(match.result_home);
                awayTeamGoals += parseInt(match.result_away);
            } else if (match.home_team_name === team2.value.team) {
                homeTeamGoals += parseInt(match.result_away);
                awayTeamGoals += parseInt(match.result_home);
            }
        });

        return {homeTeamGoals, awayTeamGoals};
    });

    const chooseTeam = (newTeam) => {
        resetFitlers();
        if (team1.value && team2.value) {
            team1.value = null;
            team2.value = null;
        }
        if (!team1.value) {
            team1.value = newTeam;
        } else if (!team2.value) {
            team2.value = newTeam;
        }

    }

    const unchooseTeam = (oldTeam) => {
        if (team1.value === oldTeam) {
            team1.value = '';
        } else if (team2.value === oldTeam) {
            team2.value = '';
        }
    }

    const isTeamSelected =(newTeam) => {
        return newTeam === team1.value || newTeam === team2.value;
    };


    onMounted(() => {
        getTeams();
        getData();
    });




</script>

<template>

    <div class="flex h-screen">
        <!-- Sidebar -->
        <div class="w-1/3 flex flex-col py-8 px-4 bg-base-200 gap-12">
        <!-- <div class="w-1/3 flex flex-col py-8 px-4 bg-base-200 gap-12 h-screen"> -->
            <label class="input input-bordered input-sm flex items-center gap-2 col-span-3 w-3/3">
                <input type="text" class="grow" placeholder="Search" v-model="searchTeamQuery"/>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    class="h-4 w-4 opacity-70">
                    <path
                    fill-rule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clip-rule="evenodd" />
                </svg>
            </label>
            <div class="overflow-auto grid grid-cols-1 lg:grid-cols-3 gap-y-4 gap-x-2 h-screen">
                <div v-for="team in filteredTeams" 
                    @click="isTeamSelected(team) ? unchooseTeam(team) : chooseTeam(team)"
                    :key="team.id" 
                    class="flex rounded-lg flex-col h-32 cursor-pointer hover:border-purple-900 hover:border-2" 
                    :class="isTeamSelected(team) ? 'bg-purple-900' : ''">
                    <div class=" h-24 flex flex-col items-center justify-center rounded-full">
                        <img :src="require(`../assets/logo/${team.img_url}`)" class="w-12 h-12 object-contain" :alt="'Logo of the: ' + team.team">
                    </div>
                    <span class="w-full text-center text-xs">{{ team.team }}</span>
                </div>
            </div>
        </div>

        <!-- Main Content -->
        <div class="w-2/3 flex flex-col items-center p-4 justify-between">

            <!-- Filters -->
            <div class="flex flex-wrap gap-8 w-full justify-center">
                <label class="form-control w-full max-w-xs">
                    <div class="label">
                        <span class="label-text">Season</span>
                    </div>
                    <select class="select select-xs lg:select-md select-ghost w-full max-w-xs" v-model="seasonSelected">
                        <option value="">All Seasons</option>
                        <option v-for="season in uniqueSeasons" :key="season" :value="season">{{ season }}</option>
                    </select>
                </label>
                <label class="form-control w-full max-w-xs">
                    <div class="label">
                        <span class="label-text ">Phase</span>
                    </div>
                    <select class="select select-xs lg:select-md select-ghost w-full max-w-xs" v-model="phaseSelected">
                        <option value="">All Phases</option>
                        <option v-for="phase in uniquePhases" :key="phase" :value="phase">{{ phase }}</option>
                    </select>
                </label>
                <label class="form-control w-full max-w-xs">
                    <div class="label">
                        <span class="label-text text-lg ">Filter by Home/Away</span>
                    </div>
                    <select class="select select-xs lg:select-md select-ghost w-full max-w-xs" v-model="homeTeamSelected">
                        <option value="">All Matches</option>
                        <option :value="team1?.team">Home Team - {{ team1?.team }}</option>
                        <option :value="team2?.team">Home Team - {{ team2?.team }}</option>
                        <!-- <option v-for="phase in uniquePhases" :key="phase" :value="phase">{{ phase }}</option> -->
                    </select>
                </label>
            </div>

            <!-- Scores -->
            <Transition>
                <div v-if="team1 && team2 && homeTeamSelected === team1?.team" class="grid grid-cols-3 gap-x-10 lg:gap-x-4 items-end text-2xl lg:text-6xl mb-16 w-full lg:w-2/3">

                    <div class="flex flex-col gap-8 justify-center items-center">
                        <img :src="require(`../assets/logo/${team1.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                        <span class="">{{ scores?.homeTeamWins}}</span>
                    </div>

                
                    <div class="flex flex-col gap-8 justify-center items-center">
                        <span>X</span>
                        <span class="">{{ scores?.draws }}</span>
                    </div>

                
                    <div class="flex flex-col gap-8 justify-center items-center">
                        <img  :src="require(`../assets/logo/${team2.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                        <span class="">{{ scores?.awayTeamWins }}</span>
                    </div>

                </div>

                <div v-else-if="team1 && team2 && homeTeamSelected === team2?.team" class="grid grid-cols-3 gap-x-10 lg:gap-x-4 items-end text-2xl lg:text-6xl mb-16 w-full lg:w-2/3">


                    <div class="flex flex-col gap-6 justify-center items-center">
                        <img  :src="require(`../assets/logo/${team2.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                        <span class="">{{ scores?.awayTeamWins }}</span>
                    </div>
                
                    <div class="flex flex-col gap-8 justify-center items-center">
                        <span>X</span>
                        <span class="">{{ scores?.draws }}</span>
                    </div>

                
                    <div class="flex flex-col gap-6 justify-center items-center">
                        <img :src="require(`../assets/logo/${team1.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                        <span class="">{{ scores?.homeTeamWins}}</span>
                    </div>

                </div>

                <div v-else-if="team1 && team2" class="grid grid-cols-3 gap-x-10 lg:gap-x-4 items-end text-2xl lg:text-6xl mb-16 w-full lg:w-2/3">

                    <div class="flex flex-col gap-6 justify-center items-center">
                        <img :src="require(`../assets/logo/${team1.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                        <span class="">{{ scores?.homeTeamWins}}</span>
                    </div>

                
                    <div class="flex flex-col gap-8 justify-center items-center">
                        <span>X</span>
                        <span class="">{{ scores?.draws }}</span>
                    </div>

                
                    <div class="flex flex-col gap-6 justify-center items-center">
                        <img  :src="require(`../assets/logo/${team2.img_url}`)" class="w-10 h-10 object-contain lg:h-16 lg:w-16" alt="">
                        <span class="">{{ scores?.awayTeamWins }}</span>
                    </div>

                </div>
            </Transition>


            <!-- Goals -->
            <Transition>
                <div v-if="team1 && team2 && homeTeamSelected === team1?.team" class="">

                    <div class="flex flex-col gap-8 justify-center items-center">

                        <span class="">{{ goals?.homeTeamGoals}}</span>
                    </div>

                
                    <div class="flex flex-col gap-8 justify-center items-center">
                        <span class="text-xl">GOALS</span>
                    </div>

                
                    <div class="flex flex-col gap-8 justify-center items-center">

                        <span class="">{{ goals?.awayTeamGoals }}</span>
                    </div>

                </div>

                <div v-else-if="team1 && team2 && homeTeamSelected === team2?.team" class="grid grid-cols-3 w-2/3 text-2xl">


                    <div class="flex flex-col gap-8 justify-center items-center">

                        <span class="">{{ goals?.awayTeamGoals }}</span>
                    </div>
                
                    <div class="flex flex-col gap-8 justify-center items-center">
                        <span class="text-xl">GOALS</span>
                    </div>

                
                    <div class="flex flex-col gap-8 justify-center items-center">

                        <span class="">{{ goals?.homeTeamGoals}}</span>
                    </div>

                </div>

                <div v-else-if="team1 && team2" class="grid grid-cols-3 gap-x-4 text-xl mb-32">

                    <div class="flex flex-col gap-8 justify-center items-center">

                        <span class="">{{ goals?.homeTeamGoals}}</span>
                    </div>
                
                    <div class="flex flex-col gap-8 items-center justify-center">
                        <span class="text-xl">GOALS</span>

                    </div>
                
                    <div class="flex flex-col gap-8 justify-center items-center">
                        <span class="">{{ goals?.awayTeamGoals }}</span>
                    </div>

                </div>
            </Transition>

            <!-- Footer -->
            <span class="text-xs text-gray-500 pb-2 text-opacity-70 bottom-0 fixed text-center"> Data includes matches from the 1998-1999 season onward.</span>
        </div>
    </div>

</template>

<style scoped>
    /* we will explain what these classes do next! */
    .v-enter-active {
    transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
    opacity: 0;
    }


</style>